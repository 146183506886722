<template>
    <div class="main">
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作区">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="储运销售公司" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="体检报告">
                            <el-date-picker style="width: 315px" v-model="search.time" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="评估状态">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                                <el-option label="已审核" value="5"></el-option>
                                <el-option label="已评估" value="2"></el-option>
                                <el-option label="已暂存" value="3"></el-option>
                                <el-option label="未评估" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" style="padding-top: 10px">
                        <el-form-item label="标签分组">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                                <el-option label="无" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="分项搜索">
                            <el-input placeholder="请输入内容" v-model="search.key" class="input-with-select"
                                style="width:400px">
                                <el-select v-model="search.select" slot="prepend" placeholder="请选择" style="width: 110px">
                                    <el-option label="姓名" value="1"></el-option>
                                    <el-option label="年龄" value="2"></el-option>
                                    <el-option label="性别" value="3"></el-option>
                                    <el-option label="工作单位" value="4"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain @click="getData">查询</el-button>
                            <el-button type="" plain @click="getData">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <el-table class="table" :data="table.data" border stripe size="mini" v-loading="loading" style="width: 100%;cursor: pointer"
            height="100%" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center"
                :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
            <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                :width="v.width" :formatter="v.formatter" show-overflow-tooltip :key="idx">
            </el-table-column>
            <el-table-column align="center" width="200" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleInfo(scope.row)">
                        健康评估
                    </el-button>
                    <el-button type="text" size="mini" @click="handleJianKang(scope.row)">
                        健康档案
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pager">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            search: {},
            table: {
                columns: [
                    { title: '名称', field: 'name', width: '' },
                    { title: '性别', field: 'gender', width: '' },
                    { title: '年龄', field: 'age', width: '' },
                    { title: '手机', field: 'phone', width: '' },
                    { title: '身份证', field: 'sfz', width: '160' },
                    { title: '状态', field: 'status', width: '' },
                    { title: '评估时间', field: 'date', width: '' },
                    { title: '评估医生', field: 'doctor', width: '' },
                    { title: '审核医生', field: 'audit', width: '' },
                    { title: '评估历史', field: 'history', width: '' },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/doctor/hi/list?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }

    .btns {
        float: right;
    }
}

.table {
    flex: 1;
    position: relative;
}

.pager {
    padding-top: 10px;
    text-align: center;
}

::v-deep .right-search .el-form-item--small .el-form-item__content,
::v-deep .right-search .el-form-item--small .el-form-item__label {
    line-height: 15px !important;
}
</style>