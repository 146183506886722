<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" content="短信平台" />
        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card" class="tabs">
            <el-tab-pane label="短信记录" name="first"><sms-log /></el-tab-pane>
            <el-tab-pane label="待发记录" name="second">配置管理</el-tab-pane>
            <el-tab-pane label="短信模板" name="third">角色管理</el-tab-pane>
            <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import SmsLog from './components/smsLog.vue'
export default {
    components: {
        SmsLog,
    },
    data() {
        return {
            activeName: 'first'
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
};
</script>

<style lang="scss" scoped>
.page{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.tabs {
    margin-top: 18px;
    flex: 1;
    box-shadow: none;
}
</style>